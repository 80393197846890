@use "@styles/core/_variables.scss" as variable;

.seperator_spacing {
  margin-top: 60px;
}

.honey_list_slider {
  text-align: center;
  background-color: #ECE8E1;
  :global{
    .titles {
      padding-top: 32px !important;
      h3{
        padding: 38px 0 30px 0 !important;
      }
      span {
        font-family: "brownlight", serif !important;
        font-size: 16px !important;
        text-transform: unset !important;
        text-align: center;
        margin: auto;
        line-height: 25.04px !important;
        letter-spacing: 0.16px !important;
      }
    }
  }
  .honey_item {
    padding-top: 45px;
    transition: all .3s ease-in-out;
    &:hover {
      transform: scale(1.02);
    }
    a {
      text-decoration: none;
      img {
        height: 174.63px;
        aspect-ratio: 1/1;
        opacity: 1;
        object-fit: contain;
        transform: translateZ(0);
        transition-duration: 1s;
        transition-timing-function: cubic-bezier(.19, 1, .22, 1);
      }
      .img_honeypacket {
        height: 200.63px;
      }
      .bar_line {
        margin: 12px auto;
        width: 20%;
        height: 1px;
        background: variable.$golden;
      }
      span {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: variable.$primary;
      }
    }
  }
  .swiper_button_prev_next {
    top: 40% !important;
  }
}
@media screen and (max-width: 480px) {
  .honey_list_slider {
    :global{
      .titles{
        padding-top: 0 !important;
        padding-bottom: 20px !important;
        .title-bold{
          padding-bottom: 20px !important;
        }
        span{
          width: 95% !important;
        }
      }
    }
  }
}
